<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as EQUAL_ORG_TYPES } from './store'
import { ROUTES as EQUAL_ORG_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import EqualOrganizationTable from './EqualOrganizationTable'
import EqualOrganizationCommonFilters from './EqualOrganizationCommonFilters'
import EqualOrganizationEdit from './EqualOrganizationEdit.vue'

export default {
  name: 'EqualOrganizationList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': EqualOrganizationTable,
    'common-filters': EqualOrganizationCommonFilters,
    'component-detail': EqualOrganizationEdit,
    'component-edit': EqualOrganizationEdit
  },
  data () {
    return {
      EQUAL_ORG_ROUTES,
      title: this.$t('Equal organizations'),
      rolePerm: ['miscellaneous_equalorganization_list'],
      actionEnablePermission: ['miscellaneous_equalorganization_enable'],
      actionDisablePermission: ['miscellaneous_equalorganization_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...EQUAL_ORG_TYPES.GENERIC.miscellaneous.equalorganization.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.EQUAL_ORG_ROUTES.EQUAL_ORGANIZATION_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('miscellaneous_equalorganization_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: EQUAL_ORG_TYPES.GENERIC.miscellaneous.equalorganization.LIST.MUTATIONS.setSelectedList,
      setSelectAll: EQUAL_ORG_TYPES.GENERIC.miscellaneous.equalorganization.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...EQUAL_ORG_TYPES.GENERIC.miscellaneous.equalorganization.LIST.ACTIONS
    })
  }
}
</script>
